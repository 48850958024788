.offlineSync-form {
  width: 100%;
  display: block;
  margin: auto;
  height: inherit;
}
.offlineSync-container {
  position: relative;
  margin: 0 auto 0px;
  display: block;
  background-color: #F8F9FA;
  height: inherit;
  width: 90%;
  text-align: left;
}
.offlineSync-form-box {
  box-sizing: border-box;
  width: 100%;
  display: block;
  margin: auto;
}
.offlineSync-form-box div:first-child, .offlineSync-form-gate div:first-child {
  border-top: 0.5px solid rgba(0, 0, 0, 0.2);
}
.offlineSync-complete {
  color: #858E96;
  font-weight: 400;
  font-size: 16px;
  font-style: italic;
  float: right;
  margin-right: 25px;
}
.offlineSync-form svg {
  height: 24px;
  color: #858E96;
  align-self: center;
  /* position: absolute;
  top: 25px;
  right: 0px; */
}
.offlineSync-form-title {
  font-size: 16px;
  line-height: 24px;
  padding: 15px 0px;
  color: #000;
  text-transform: capitalize;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.2);
  /* position: relative; */
  min-height: 80px;
  display:flex;
  justify-content: space-between;
}
.offlineSync-top {
  display: flex;
  align-items: center;
  padding-top: 30px;
  padding-bottom: 14px;
  justify-content: center;
}
.offlineSync-details {
  padding-right: 37px;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.75px;
  color: #495057;
  width: 100%;
  text-align: center;
}
.scan-offlineSync-text {
  position: absolute;
  top: 105px;
  width: 100%;
  left: 0;
  right: 0;
  z-index: 1;
  color: #fff;
  font-weight: 600;
}
.offlineSync-date {
  padding: 15px 0;
  font-weight: 700;
}
.offlineSync-title {
  padding: 10px 10px 0;
  color: #858E96;
  font-weight: 600;
}
.offlineSync-title.active {
  color: #202529;
}
.offlineSync-value {
  font-weight: 600;
  font-size: 32px;
  line-height: 44px;
  color: #263238;
  padding: 10px;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.2);
}
.offlineSync-value.status {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}
.offlineSync-sync-btn {
  position: absolute;
  width: 100%;
  height: 56px;
  color: #fff;
  text-transform: uppercase;
  font-weight: bold;
  display: block;
  font-size: 16px;
  background: #0067FF;
  border-radius: 14px;
  bottom: 70px;
}
.offlineSync-sync-btn:disabled {
  background: #66A3FF;
  color: rgba(255, 255, 255, 0.3);
}