@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");
html {
  height: 100%;
}
body {
  height: 100%;
  margin: 0;
  font-family: Inter, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overscroll-behavior-y: contain;
  background-color:#F8F9FA !important;
}

body::after {
  content: "";
  position: fixed;
  width: 100%;
  height: 50%;
  bottom: 0;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  z-index: -1;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  text-align: left;
  height: 100%;
}
button {
  border: none;
  padding: 8px 16px;
  text-decoration: none;
}
button:hover {
  text-decoration: none;
  cursor: pointer;
}
.back-btn {
  font-weight: 700;
  color: #0067FF;
  align-self: flex-start;
  cursor: pointer;
  margin-top: -5px;
}
a {
  color: #333333 !important;
  text-decoration: none;
}
a:hover {
  text-decoration: none !important;
}
