.syncProfile-form {
  width: 100%;
  display: block;
  margin: auto;
}
.syncProfile-container {
  position: relative;
  margin: 0 auto 0px;
  display: block;
  background-color: #F8F9FA;
  height: inherit;
  width: 90%;
  text-align: left;
}
.syncProfile-form-box {
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  padding: 18px;
  display: block;
  margin: auto;
  top: 10vh;
}
.syncProfile-form-title {
  font-size: 32px;
  font-weight: bolder;
  padding: 36px 0px 0;
  color: #171717;
  text-transform: capitalize;
  text-align: center;
  margin-top: 106px;
}
.syncProfile-form-desc {
  height: 40px;
  max-width: 320px;
  left: 48px;
  top: 315px;
  margin: auto;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  color: #495057;
  padding: 15px 0;
  margin-bottom: 30px !important;
}
/* msg box */
.sync-msg-box {
  display: flex;
  align-items: center;
  padding-top: 30px;
  justify-content: center;
}
.syncProfile-details {
  padding-left: 8px;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.75px;
  color: #495057;
}
.syncProfile-progress-percent {
  color: #A2A2A2;
  font-size: 14px;
  text-align: center;
  margin-top: 10px;
}
.syncProfile-progress-percent.right {
  text-align: right;
}
.syncProfile-form-box .progress {
  border-radius: 100px;
  height: 20px;
  background-color: #385682;
}
.syncProfile-form-box .progress-bar {
  background-color: #5297FF;
}
.sync-msg {
  position: absolute;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  bottom: 38px;
  height: 44px;
  color: #FFFFFF;
  line-height: 20px;
  font-weight: 600;
  text-align: center;
  left: 0;
}
.sync-success {
  background: #2ACA59;
}
.sync-fail, .toast-msg.sync.fail {
  background: #FF1842;
  white-space: pre-line;
  height: 61px;
}
.toast-msg.sync {
  bottom: 38px;
  left: 0;
  text-align: center;
}