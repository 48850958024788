.setting-container {
  width: 90%;
  margin: auto;
  background-color: #f8f9fa;
  height: inherit;
}
.mode-selection {
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  border: 0.5px solid rgba(0, 0, 0, 0.2);
  border-radius: 100px;
  padding: 5px;
}
.mode-container {
  padding-bottom: 15px;
  border-top: 0.5px solid rgba(0, 0, 0, 0.2);
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.2);
}
.setting-title {
  padding: 15px 0 0;
  font-weight: bold;
  font-size: 12px;
  color: #858e96;
  line-height: 20px;
  text-transform: uppercase;
  padding-bottom: 10px;
}
.setting-button,
.validator-button,
.voucher-button {
  width: 100%;
  height: 30px;
  font-size: 16px;
  border-radius: 100px;
  color: #0067ff;
  background-color: transparent;
  font-weight: 700;
}
.setting-button:hover,
.validator-button:hover,
.voucher-button:hover {
  background-color: #0067ff;
  color: #ffffff;
}
.setting-button.active,
.validator-button.active,
.voucher-button.active {
  width: 100%;
  height: 30px;
  background-color: #0067ff;
  font-size: 16px;
  border-radius: 100px;
  color: #ffffff;
  font-weight: 700;
}
.disabled-button-validator {
  width: 100%;
  height: 30px;
  font-size: 16px;
  border-radius: 100px;
  font-weight: 700;
  background-color: #e0e0e0;
  color: #9e9e9e;
}
.emergency-button {
  width: 100%;
  height: 30px;
  background-color: transparent;
  color: #e95861;
  border-radius: 100px;
  font-size: 16px;
  font-weight: 700;
}
.emergency-button.active {
  background-color: #e95861;
  color: #ffffff;
}
.emergency-button:hover {
  background-color: #e95861;
  color: #ffffff;
}

.disabled-button-emergency {
  width: 100%;
  height: 30px;
  border-radius: 100px;
  font-size: 16px;
  font-weight: 700;
  background-color: #e0e0e0;
  color: #9e9e9e;
  cursor: not-allowed;
}

.scanner-info {
  box-sizing: border-box;
  margin: 24px;
  width: 90%;
  color: #000;
  margin-top: 0;
}
.scanner-info p {
  box-sizing: border-box;
  letter-spacing: 0.25px;
  text-align: center;
}
.profile-username {
  color: #343a40;
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
}
.profile-scannername {
  color: #495057;
  font-size: 14px;
  line-height: 20px;
}
.app-version {
  position: fixed;
  bottom: 12px;
  color: #ffffff;
  left: 0;
  right: 0;
  text-align: center;
  font-size: 12px;
}
.setting-top {
  display: flex;
  align-items: center;
  padding-top: 30px;
  padding-bottom: 14px;
  justify-content: center;
}
.setting-logout {
  font-weight: 700;
  color: #e95861;
  cursor: pointer;
}
.profile-avatar {
  padding: 27px 0;
  width: 79px;
  height: 79px;
  background-color: #fa8c16;
  border-radius: 50%;
  text-align: center;
  margin: auto;
  margin-bottom: 5px;
  font-size: 20px;
  color: #ffffff;
  font-weight: 600;
  text-transform: uppercase;
}
.MuiButton-root {
  text-transform: capitalize;
}
.setting-content div {
  padding: 25px 0;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.2);
  font-weight: 600;
  color: #495057;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.setting-content div svg {
  color: #66a3ff;
  margin-right: 15px;
  font-size: 21px;
}
.setting-content a,
.setting-top a {
  text-decoration: none;
}
.reset {
  color: #e95861;
  font-weight: 700;
  text-align: center;
  width: inherit;
  text-shadow: 0px 1px 10px rgb(0 103 255 / 10%);
  padding: 20px 0;
}
.reset-container {
  height: 100vh;
  display: block;
  margin: auto;
  width: 90%;
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 25%;
}
.reset-title {
  font-size: 32px;
  font-weight: bolder;
  text-transform: capitalize;
  padding: 36px 0px 0;
}
.reset-desc {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  padding: 15px 0 45px 0px;
}
.reset-progress-percent {
  color: #a2a2a2;
  font-size: 14px;
  text-align: center;
  margin-top: 10px;
}
.reset-container .progress {
  border-radius: 100px;
  height: 20px;
  background-color: #385682;
}
.reset-container .progress-bar {
  background-color: #5297ff;
}
.reset-progress-percent.right {
  text-align: right;
}
.setting-fetchLatestConfig {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
