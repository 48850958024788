.viewaudit-form {
  width: 100%;
  display: block;
  margin: auto;
  height: inherit;
}
.viewaudit-container {
  position: relative;
  margin: 0 auto 0px;
  display: block;
  background-color: #F8F9FA;
  height: inherit;
  width: 90%;
  text-align: left;
}
.viewaudit-form-box {
  box-sizing: border-box;
  width: 100%;
  display: block;
  margin: auto;
}
.viewaudit-form svg {
  float: right;
  height: 24px;
}
.viewaudit-form-title {
  font-size: 16px;
  line-height: 24px;
  padding: 15px 0px;
  color: #000;
  text-transform: capitalize;
}
.viewaudit-top {
  display: flex;
  align-items: center;
  padding-top: 30px;
  padding-bottom: 14px;
  justify-content: center;
}
.viewaudit-details {
  padding-right: 37px;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0.75px;
  color: #495057;
  width: 100%;
  text-align: center;
  margin-top: -5px;
}
.scan-override-text {
  position: absolute;
  top: 105px;
  width: 100%;
  left: 0;
  right: 0;
  z-index: 1;
  color: #fff;
  font-weight: 600;
}
.audit-details-title {
  font-weight: bold;
  margin-bottom: 20px;
}
.audit-info {
  font-size: 14px;
  padding-bottom: 20px;
  padding-top: 20px;
  border-bottom: 1px solid #ccc;
}
.audit-info .status-color {
  color: green;
}
.audit-info .status-color.red {
  color: red;
}
.audit-info div {
  margin-bottom: 5px;
}
.audit-info div.col-5 {
  font-weight: bold;
}