.report-form {
  width: 100%;
  display: block;
  margin: auto;
  height: inherit;
}
.report-container {
  position: relative;
  margin: 0 auto 0px;
  display: block;
  background-color: #f8f9fa;
  height: inherit;
  width: 90%;
  text-align: left;
}
.report-container-box {
  box-sizing: border-box;
  margin: 20px auto 12px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  background-color: #ffffff;
  padding: 15px;
  border-radius: 10px;
  filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.35));
}
.report-formControl {
  margin: auto;
  width: 250px;
  background-color: #fca311;
}
.report-select::before {
  border-bottom: none;
}
.report-select::after {
  border-bottom: none;
}
.report-select .MuiFilledInput-input {
  padding: 22px 12px 16px;
  text-align: center;
}
.report-data-blocks {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}
.report-pie-chart-block {
  position: relative;
  width: 100%;
  height: 300px;
}
.report-pie-chart-block-pending {
  .ant-skeleton-avatar {
    width: 100% !important;
    height: 100% !important;
  }
  &&& {
    width: 100%;
    height: 100%;
  }
}
.pie-chart-label {
  position: absolute;
  top: 45%;
  transform: translateY(-30%);
  left: 0;
  right: 0;
  font-size: 48px;
  font-weight: 400;
  text-align: center;
  color: #003e99;
  line-height: 26px;
}
.report-pie-chart-block .recharts-default-legend li {
  font-size: 12px;
  font-weight: 600;
  margin-right: 20px !important;
}
.total-ticket-block,
.remain-ticket-block {
  width: 100%;
  border-radius: 10px;
  margin: 0px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  padding: 12px;
  box-sizing: border-box;
}
.scan-ticket-block {
  width: 250px;
  border-radius: 10px;
  margin: 0px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: row;
  padding: 12px;
  box-sizing: border-box;
}
.report-ticket-title {
  color: #263238;
  font-size: 16px;
  font-weight: 600;
  padding-bottom: 5px;
}
.report-ticket-number {
  font-weight: 600;
  font-size: 14px;
  color: #858e96;
}
.report-ticket-icon {
  margin-right: 12px;
  color: #fca311;
}
@media only screen and (max-width: 600px) {
  .report-data-blocks {
    display: block;
    width: 100%;
  }
}

.label-text {
  color: #263238;
  font-size: 14px;
  font-weight: 600;
}
.report-top {
  display: flex;
  align-items: center;
  padding-top: 30px;
  padding-bottom: 14px;
  justify-content: center;
}
.report-details {
  padding-right: 37px;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.75px;
  color: #495057;
  width: 100%;
  text-align: center;
}
.report-pie-chart-block .recharts-default-legend li span {
  vertical-align: middle;
}
.report-details-title {
  color: #858e96;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  padding: 5px 0 0;
}
.report-details-text {
  color: #263238;
  font-weight: 600;
  font-size: 32px;
  padding: 0 0 5px;
}

.report-details-sub-container {
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.2);
}

.report-section-title {
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.report-scan-in-details {
  width: 50%;
  align-items: start;
}

.report-scan-out-details {
  width: 50%;
  align-items: start;
}

.report-gate-name {
  font-weight: 700;
  color: #858e96;
  font-size: 16px;
  padding: 24px 0px;
}

.scan-count-report-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.scan-count-report-name {
  font-weight: 700;
  color: #858e96;
  font-size: 16px;
}

.scan-count-report-details-container {
  padding: 10px 0;
}

.scan-count-report-details-title {
  color: #858e96;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  padding: 0 0 5px;
}
.scan-count-report-details-list {
  /* width: 100%; */
  display: flex;
  /* justify-content: space-between; */
}

.scan-count-report-details-name {
  color: #263238;
  font-weight: 600;
  font-size: 16px;
  padding: 0 15px 0;
}

.scan-count-report-details-text {
  color: #263238;
  font-weight: 400;
  font-size: 16px;
}

.scan-count-list {
  display: flex;
  flex-direction: column;
}

.scan-count-list-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-size: 16px;
  padding: 15px 0px;
  color: #000;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.2);
  /* position: relative; */
  min-height: 80px;
}

.scan-count-form-box {
  box-sizing: border-box;
  width: 100%;
  display: block;
  margin: auto;
}

.scan-count-date {
  padding: 15px 0;
  font-weight: 700;
  color: #202529;
}

.scan-count-title {
  padding: 10px 5px 0;
  color: #858E96;
  font-weight: 600;
}

.scan-count-value {
  font-weight: 600;
  font-size: 32px;
  line-height: 44px;
  color: #263238;
  padding: 10px 16px;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.2);
}