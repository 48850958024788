.barcodeaudit-container {
  position: absolute;
  margin: 0 auto 0px;
  width: 90%;
  left: 22px;
}
.barcodeaudit-top {
  display: flex;
  align-items: center;
  padding-top: 30px;
  justify-content: center;
}
.barcodeaudit-details {
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.75px;
  color: #fff;
  width: 100%;
  text-align: center;
  z-index: 1;
  margin-top: -10px;
  margin-left: -40px;
}
.barcodeaudit-details.manual-entry {
  color: #000;
}
.back-btn.barcodeaudit {
  color: #fff;
  z-index: 2;
  pointer-events: auto;
}
.back-btn.manual-entry {
  z-index: 2;
  pointer-events: auto;
}
.video-container.barcodeaudit .scan-here-text {
  width: 100%;
  text-align: center;
}
.scan-info {
  color: #fff;
  text-align: center;
  margin-top: 20px;
  padding: 0px 30px;
}