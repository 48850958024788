.override-form {
  width: 100%;
  display: block;
  margin: auto;
  height: inherit;
}
.override-container {
  position: relative;
  margin: 0 auto 0px;
  display: block;
  background-color: #F8F9FA;
  height: inherit;
  width: 90%;
  text-align: left;
}
.override-form-box {
  box-sizing: border-box;
  width: 100%;
  display: block;
  margin: auto;
}
.override-form-box div:first-child, .override-form-gate div:first-child {
  border-top: 0.5px solid rgba(0, 0, 0, 0.2);
}
.override-form svg {
  float: right;
  height: 24px;
}
.override-form-title {
  font-size: 16px;
  line-height: 24px;
  padding: 15px 0px;
  color: #000;
  text-transform: capitalize;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.2);
}
.override-top {
  display: flex;
  align-items: center;
  padding-top: 30px;
  padding-bottom: 14px;
  justify-content: center;
}
.override-details {
  padding-right: 37px;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.75px;
  color: #495057;
  width: 100%;
  text-align: center;
}
.scan-override-text {
  position: absolute;
  top: 105px;
  width: 100%;
  left: 0;
  right: 0;
  z-index: 1;
  color: #fff;
  font-weight: 600;
}