.event-form {
  width: 100%;
  display: block;
  margin: auto;
  height: inherit;
}
.event-container {
  position: relative;
  margin: 0 auto 0px;
  display: block;
  background-color: #F8F9FA;
  height: inherit;
  width: 90%;
  text-align: left;
}
.event-form-box {
  box-sizing: border-box;
  width: 100%;
  display: block;
  margin: auto;
}
.event-form-box div:first-child, .event-form-gate div:first-child {
  border-top: 0.5px solid rgba(0, 0, 0, 0.2);
}
.event-form svg {
  float: right;
  height: 24px;
}
.event-form-gate {
  box-sizing: border-box;
  width: 100%;
  display: block;
  margin: auto;
}
.event-form-title {
  font-size: 16px;
  line-height: 24px;
  padding: 15px 0px;
  color: #000;
  text-transform: capitalize;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.2);
}
.event-form-desc {
  height: 40px;
  max-width: 320px;
  left: 48px;
  top: 315px;
  margin: auto;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  color: #495057;
  padding: 15px 0;
  margin-bottom: 30px !important;
}
.event-top {
  display: flex;
  align-items: center;
  padding-top: 30px;
  padding-bottom: 14px;
  justify-content: center;
}
.event-details {
  padding-right: 37px;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.75px;
  color: #495057;
  width: 100%;
  text-align: center;
}
.gate-details {
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.75px;
  color: #495057;
  width: 100%;
  text-align: center;
}