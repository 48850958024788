.login-page {
  width: 100%;
  height: inherit;
}
.login-form,
.pairScanner-form {
  width: 100%;
  display: block;
  margin: auto;
  height: inherit;
}
.login-container,
.pairScanner-container {
  position: relative;
  margin: 0 auto 0px;
  display: block;
  background-color: #f8f9fa;
  height: inherit;
  width: 90%;
  text-align: left;
}
.login-form-box,
.pairScanner-form-box {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  padding: 18px;
  display: block;
  margin: auto;
  padding-top: 5vh;
}
.pairScanner-btm-container {
  width: 100%;
  height: 149px;
  position: relative;
}
.login-btm-container {
  width: 100%;
}
.login-app-logo,
.pairScanner-app-logo {
  display: block;
  width: 106px;
  margin: auto;
}
.login-form-title,
.pairScanner-form-title {
  font-size: 32px;
  font-weight: bolder;
  padding: 36px 0px 0;
  color: #171717;
  text-transform: capitalize;
  text-align: center;
}
.form-username-group,
.form-password-group,
.form-scannerId-group {
  margin-bottom: 12px;
}
.form-username,
.form-password,
.form-scannerId,
.form-pin {
  background-color: transparent;
  border: 0;
  border-bottom: 1px solid #cecece;
  width: 100%;
  height: 40px;
  padding: 6px;
  box-sizing: border-box;
  margin-top: 15px;
}
.login-username-lable,
.login-password-lable,
.pair-scanner-lable {
  font-size: 14px;
  margin: 0;
  color: #333333;
}
.button {
  height: 56px;
  color: #fff;
  text-transform: uppercase;
  font-weight: bold;
  display: block;
  font-size: 16px;
  background: #0067ff;
  border-radius: 14px;
  bottom: 100px;
  width: 100%;
  margin-bottom: 15px;
}
.form-error-msg {
  font-size: 12px;
  color: #bf0603;
  font-weight: bold;
}
.unpair-scanner {
  width: 90%;
  display: flex;
  justify-content: right;
}
.unpair-scanner-btn {
  background-color: transparent;
  border: none;
  color: #ffffff;
  text-transform: uppercase;
  padding: 8px 0px;
  letter-spacing: 1px;
}
.confirm-dialog.unpair {
  top: 50%;
  transform: translateY(-50%);
  width: 90%;
  font-size: 16px;
}

/* new */
.login-form-desc,
.pairScanner-form-desc {
  height: 40px;
  max-width: 320px;
  left: 48px;
  top: 315px;
  margin: auto;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  color: #495057;
  padding: 15px 0;
  margin-bottom: 30px !important;
}
.pairScanner-form-desc {
  max-width: 200px;
}
input::placeholder {
  font-size: 16px;
}
.button:disabled {
  background: #66a3ff;
  color: rgba(255, 255, 255, 0.3);
}
input:focus-visible {
  outline: none;
}
/* pagination */
.pagination {
  bottom: 60px;
  text-align: center;
  margin: auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
}
.dot {
  display: inline-block;
  width: 8px;
  height: 8px;
  background: #cce0ff;
  margin: 5px;
  border-radius: 100px;
}
.dot.active {
  width: 12px;
  height: 12px;
  background: #0052cc;
}
.pagination-text {
  bottom: 35px;
  text-align: center;
  margin: auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  margin-bottom: 20px;
}
.toast-msg {
  position: absolute;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  height: 44px;
  color: #ffffff;
  line-height: 20px;
  font-weight: 600;
  left: -5vw;
}
.toast-msg.success,
.toast-msg.success {
  background: #2aca59;
}
.toast-msg.fail,
.toast-msg.fail {
  background: #ff1842;
}

/* msg box */
.login-msg-box {
  display: flex;
  align-items: center;
  padding-top: 20px;
}
.scanner-details {
  padding-left: 8px;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.75px;
  color: #495057;
}
.unpair-btn {
  margin-left: auto;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  text-align: right;
  letter-spacing: 0.75px;
  color: #0067ff;
  cursor: pointer;
}
