.main-container {
  text-align: center;
  height: 100%;
  width: 100%;
}
.scanner-off {
  width: 100%;
  height: 400px;
  margin: auto;
  background-color: #000000;
}
.scan-btn {
  width: 200px;
  height: 40px;
  font-size: 18px;
  background-color: #fca311;
  color: #ffffff;
  margin-top: 12px;
}
.scan-btn-group {
  width: 144px;
  margin: auto;
  left: 0;
  right: 0;
  border: solid 1px #f2f7ff;
  border-radius: 5px;
  position: absolute;
  z-index: 1;
  top: 60px;
}
.scan-in-btn,
.scan-out-btn {
  width: 71px;
  height: 100%;
  box-sizing: border-box;
  border: 0;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: bold;
  background-color: transparent;
  color: #f2f7ff;
  padding: 5px 16px;
}
.scan-in-btn.active {
  background-color: #f2f7ff;
  color: #0067ff;
  border-radius: 3px 0 0 3px;
}
.scan-out-btn.active {
  background-color: #f2f7ff;
  color: #0067ff;
  border-radius: 0 3px 3px 0;
}
.barcode-input-dialog .MuiDialog-paperFullWidth {
  border-radius: 15px;
}
.dialog-close-btn {
  text-align: end;
  padding: 12px 12px 0px;
  cursor: pointer;
}
.barcode-input-title {
  text-align: center;
  font-weight: bold;
  text-transform: uppercase;
}
.barcode-input-field {
  width: 200px;
  margin: auto;
  display: block;
}
.barcode-submit-btn {
  background-color: #bf0306;
  width: 200px;
  margin: 24px auto 64px;
  display: block;
  height: 40px;
  color: #ffffff;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 18px;
}
.result-display-container {
  width: 90%;
  border-radius: 14px;
  display: block;
  margin: auto;
  z-index: 1300;
  padding: 15px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
}
.big{
  height: 350px;
  bottom: 30%;
}
.result-display-container.valid,
.result-display-container.invalid.reason {
  background-color: #2aca59;
}
.result-display-container.invalid {
  background-color: #e95861;
}
.result-display-container.valid.scanCheck {
  background-color: #fd9245;
}
.permission-denied {
  height: 200px;
  box-sizing: border-box;
  color: #ffffff;
  padding: 64px;
}

.confirm-dialog {
  position: absolute;
  border-radius: 5px;
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.2);
  left: 0;
  right: 0;
  width: 80%;
  height: 130px;
  margin: auto;
  background-color: #ffffff;
}
.confirm-dialog p {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 65px;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.2);
}
.btn-group {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 65px;
}
.dialog-confirm,
.dialog-cancel {
  width: 40%;
  height: 40px;
  color: #fff;
  text-transform: uppercase;
  font-weight: bold;
  display: block;
  font-size: 14px;
  background: #adb5bd;
  border-radius: 14px;
}
.dialog-confirm {
  background: #0067ff;
}
.splash-screen-background {
  background-color: #0052cc;
  width: 100vw;
  height: 100vh;
}
.splash-screen-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
}
.splash-screen-container img {
  margin: 12px 0px;
}

/* POPUP */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.popup {
  width: 100%;
  margin-top: 12px;
  margin-left: 8px;
  margin-right: 8px;
  background-color: #FFFBE6;
  border-radius: 4px;
  border: 1px solid #FFE58F;
  padding: 12px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.popup-header {
  width: 100%;
  display: flex;
  align-items: center;
}

.popup-header .popup-title {
  margin-left: 8px;
  flex-grow: 1;
  text-align: left;
}

.popup-footer .popup-submit-btn {
  color: #1890FF;
  font-weight: 600;
  background-color: transparent;
  padding: 0;
  margin-left: 24px;
}