.manual-form {
  width: 100%;
  display: block;
  margin: auto;
  height: inherit;
}
.manual-container {
  position: relative;
  margin: 0 auto 0px;
  display: block;
  background-color: #f8f9fa;
  height: inherit;
  width: 90%;
  text-align: left;
}
.manual-type-container {
  padding-bottom: 15px;
}
.manual-type-selection {
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  border: 0.5px solid rgba(0, 0, 0, 0.2);
  border-radius: 100px;
  padding: 5px;
}
.manual-type-button {
  width: 100%;
  height: 30px;
  font-size: 14px;
  border-radius: 100px;
  color: #0067ff;
  background-color: transparent;
  font-weight: 700;
  padding: 0px;
}
.manual-type-button:hover {
  background-color: #0067ff;
  color: #ffffff;
}
.manual-type-button.active {
  background-color: #0067ff;
  color: #ffffff;
}
.manual-form-box {
  box-sizing: border-box;
  width: 100%;
  display: block;
  margin: auto;
}
.manual-form-title {
  font-size: 16px;
  line-height: 24px;
  padding: 15px 0px;
  color: #000;
  text-transform: capitalize;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.2);
}
.manual-top {
  display: flex;
  align-items: center;
  padding-top: 30px;
  padding-bottom: 14px;
  justify-content: center;
}
.manual-details {
  padding-left: 28px;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.75px;
  color: #495057;
  width: 100%;
  text-align: center;
}
.manual-override {
  color: #999;
  font-weight: 700;
}
.manual-override.active {
  color: #0067ff;
}
.manual-validate-btn {
  position: absolute;
  width: 100%;
  height: 56px;
  color: #fff;
  text-transform: uppercase;
  font-weight: bold;
  display: block;
  font-size: 16px;
  background: #0067ff;
  border-radius: 14px;
  bottom: 70px;
}
.manual-validate-btn:disabled {
  background: #66a3ff;
  color: rgba(255, 255, 255, 0.3);
}
.scan-manual-text {
  position: absolute;
  top: 105px;
  width: 100%;
  left: 0;
  right: 0;
  z-index: 1;
  color: #fff;
  font-weight: 600;
}
.form-ticket-group {
  width: 90%;
  margin: auto;
}
.form-ticketcode {
  background-color: transparent;
  border: 0;
  border-bottom: 1px solid #cecece;
  width: 100%;
  height: 40px;
  padding: 6px;
  box-sizing: border-box;
  margin-top: 15px;
}
.manual-container .result-display-container {
  width: 100%;
  left: 0;
  top: auto;
  bottom: 150px;
}
.manual-ticket-group {
  width: 90%;
  margin: auto;
  position: relative;
}
.transaction-tickets-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  font-weight: bold;
  margin-top: 24px;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.2);
  padding-bottom: 6px;
}
.transaction-tickets-display {
  position: relative;
  height: 30vh;
  overflow-y: scroll;
  padding: 4px;
}
.transaction-ticket {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.2);
  height: 50px;
}
.transaction-ticket-id {
  font-weight: 700;
}
.transaction-ticket-status {
  font-style: italic;
  color: rgba(0, 0, 0, 0.5);
}
.transaction-tickets-total {
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-size: 12px;
  font-weight: 700;
  height: 35px;
}
.transaction-tickets-submit {
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-size: 12px;
  font-weight: 700;
  height: 40px;
  background-color: rgba(0, 0, 0, 0.1);
}
.form-totalTicketSubmit {
  background-color: transparent;
  border: 0;
  border-bottom: 1px solid #cecece;
  width: 40px;
  box-sizing: border-box;
  text-align: center;
}
.confirm-dialog.manual-admit {
  bottom: 25%;
  transform: translateY(-50%);
  font-size: 14px;
}
.admit-aler-msg {
  font-size: 12px;
  text-align: center;
  white-space: pre-line;
}
.manual-input-clear {
  position: absolute;
  bottom: 10px;
  right: 0px;
}
.loading-icon {
  position: absolute;
  top: 0;
  width: 99%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.spin-animation {
  display: flex;
  align-items: center;
  justify-content: center;
  animation: spin 1s infinite linear;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/* .result-display-container.scanCheck { */
/* .result-display-container.valid.scanCheck {
  background-color: #FD9245;
} */
.last-scanned-result.manual-entry {
  display: block;
  color: #000;
  padding-bottom: 5px;
  text-align: center;
}
.scanner-btm {
  width: 90%;
  position: absolute;
  right: 0;
  left: 0;
  margin: auto;
  bottom: 150px;
}