.video-container .drawingBuffer {
  display: none;
}
.viewport {
  /* position: absolute; */
  /* height: 100%; */
}
.video-container video {
  width: 100%;
  object-fit: cover;
  position: absolute;
  left: 0;
  right: 0;
}
.camera-loading {
  animation: spin 2s linear infinite;
  font-size: 32px;
  color: #14213d;
  position: absolute;
  width: 100vw;
  top: 45vh;
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.camera-overlay {
  position: absolute;
  top: 0;
  /* width: 30%; */
  height: 100%;
  margin: auto;
  left: 0;
  right: 0;
  z-index: 0;
}
.scan-container {
  width: 100%;
  position: absolute;
  top: 23%;
}
.scan-info {
  position: relative;
}
.scan-area-border {
  position: relative;
  box-sizing: border-box;
  display: block;
  margin: auto;
  width: 80%;
  height: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  top: 23%;
  z-index: -1;
  box-shadow: 0 0 0 2000px rgb(0 0 0 / 25%);
  border-radius: 50px;
  overflow: hidden;
}
.scan-area-border.short {
  border-radius: 44px;
}
.scan-here-text {
  position: absolute;
  color: #ffffff;
  left: 0;
  right: 0;
  top: 15%;
  font-weight: 600;
}
.enter-barcode-btn {
  display: block;
  box-sizing: border-box;
  padding: 16px 0;
  color: #0067ff;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  width: 100%;
  background-color: #f2f7ff;
  border-radius: 14px;
  margin-top: 10px;
}
.enter-barcode-btn:disabled {
  color: #cce0ff;
  opacity: 0.75;
}
.camera-error {
  position: absolute;
  color: #ffffff;
  top: 50%;
  left: 0;
  right: 0;
}
@media only screen and (max-width: 600px) {
  .viewport video {
    width: 100%;
    height: inherit;
    /* object-fit: cover; */
  }
  .camera-overlay {
    width: 100%;
  }
}
@media (min-width: 768px) and (max-width: 992px) {
  /* .viewport video {
    width: 55%;
  }
  .camera-overlay {
    width: 55%;
  } */
}

.scan-box {
  display: flex;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 14px;
  justify-content: center;
  position: absolute;
  width: 90%;
  z-index: 999;
  left: 5%;
  right: 5%;
}
.scan-menu-btn {
  align-self: flex-start;
  cursor: pointer;
  cursor: pointer;
}
.scan-color {
  width: 12px;
  height: 12px;
  background: #2aca59;
  display: inline-block;
  border-radius: 50%;
  margin-right: 5px;
}
.scan-color.red {
  background: #e95861;
}
.scan-status {
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.75px;
  color: #fff;
  width: 100%;
  text-align: center;
}
.scan-override {
  font-weight: bold;
  color: #999999;
  background: none;
  padding: 0;
}
.scan-override.active {
  color: #fff;
}
.MuiIconButton-edgeStart {
  margin: 0;
}
.MuiPaper-root.MuiAppBar-root {
  background-color: transparent;
}
.MuiTypography-h6 {
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-weight: bold;
  padding-left: 44px;
}
.MuiPaper-elevation4 {
  box-shadow: none;
}
.event-gate {
  color: #fff;
  font-weight: 700;
  width: 100%;
  margin-top: 12px;
}
.event-gate.short {
  top: 69%;
}
.scanAnimation {
  animation-name: MOVE-BG;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  -webkit-animation-name: MOVE-BG;
  -webkit-animation-duration: 2s;
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  background: url('./../assets/images/scanani.png') 0% 0% no-repeat;
  background-size: contain;
  height: 100%;
  width: 100%;
  position: absolute;
}
@-webkit-keyframes MOVE-BG {
  from {
    -webkit-transform: translateX(0);
  }
  to {
    -webkit-transform: translateY(-100%);
  }
}
@keyframes MOVE-BG {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateY(-100%);
  }
}
audio {
  display: none;
}
.scanner-bottom {
  width: 90%;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  bottom: 30px;
}
.last-scanned-result {
  display: block;
  color: #ffffff;
  padding-bottom: 5px;
}